<template>
  <el-tabs v-loading="loading" v-model="activeName" type="border-card" style="min-height: 35rem">
    <el-tab-pane label="页面信息" name="basic">
      <basic v-model="form" @submit="submit"></basic>
    </el-tab-pane>
    <el-tab-pane v-if="form.id > 0" label="DIY" name="design">
      <design v-if="activeName === 'design'" v-model="form" @submit="submit"></design>
    </el-tab-pane>
    <el-tab-pane v-if="form.id > 0" label="红包" name="redPacket">
      <redPacket v-if="activeName === 'redPacket'" :custom_id="form.id"></redPacket>
    </el-tab-pane>
    <el-tab-pane label="海报" name="poster">
      <poster v-model="form" @submit="submit"></poster>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import basic from "@/view/plugin/voiceRedPacket/design/edit/basic";
import design from "@/view/plugin/voiceRedPacket/design/edit/design";
import poster from "@/view/plugin/voiceRedPacket/design/edit/poster";
import redPacket from "@/view/plugin/voiceRedPacket/design/edit/redPacket";
export default {
  name: "edit",
  components:{
    basic,
    design,
    poster,
    redPacket
  },
  data(){
    return{
      activeName:"redPacket",
      edit_id:false,
      loading:false,
      form:{
        content:"",

        bg_color:"",
        bg_audio:"",
        page_title:"",
        share_title:"",
        share_desc:"",
        share_pic:"",
        countdown_time:"",

        bg:"",
        qrcode_size:0,
        qrcode_left:0,
        qrcode_top:0,
        avatar_size:0,
        avatar_left:0,
        avatar_top:0,
        nickname_left:0,
        nickname_top:0,
        nickname_size:0,
        nickname_color:"",
      },
    }
  },
  mounted() {
    if(this.$route?.query?.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.plugin.voiceRedPacket.designOne({id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$api.plugin.voiceRedPacket.designEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.edit_id ? this.load() : this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>