<template>
  <div>
    <el-table border :data="list">
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="title" label="红包标题"></el-table-column>
      <el-table-column prop="command" label="口令"></el-table-column>
      <el-table-column prop="total_price" label="总金额"></el-table-column>
      <el-table-column prop="total_num" label="总数量"></el-table-column>
      <el-table-column>
        <template #header>
          <el-button @click="chooseBox=true" size="small">选择红包</el-button>
        </template>
        <template #default="s">
          <el-button @click="remove(s.row)" size="small">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="选择红包" :visible.sync="chooseBox">
      <el-table border :data="wait_list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="红包标题"></el-table-column>
        <el-table-column prop="command" label="口令"></el-table-column>
        <el-table-column prop="total_price" label="总金额"></el-table-column>
        <el-table-column prop="total_num" label="总数量"></el-table-column>
        <el-table-column>
          <template #default="s">
            <el-button @click="choose(s.row)" size="small">选择红包</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "redPacket",
  props:{
    custom_id:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      list:[],
      wait_list:[],
      chooseBox:false,
      searchForm:{
        title:""
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    remove(item){
      this.$api.plugin.voiceRedPacket.setCustom({
        custom_id: 0,
        red_packet_id:item.id
      }).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    choose(item){
      this.$api.plugin.voiceRedPacket.setCustom({
        custom_id: this.custom_id,
        red_packet_id:item.id
      }).then(()=>{
        this.$message.success("操作成功")
        this.load();
        this.chooseBox=false;
      })
    },
    load(){
      this.$api.plugin.voiceRedPacket.list({
        page:-1,
        custom_id:this.custom_id
      }).then(res=>{
        this.list = res.list;
      })
      this.$api.plugin.voiceRedPacket.list({
        page:-1,
        custom_id:0,
        status:1,
      }).then(res=>{
        this.wait_list = res.list;
      })
    },
  }
}
</script>

<style scoped>

</style>